"use strict";
require('browsernizr/test/touchevents');
require('browsernizr');
var $ = window.jQuery = window.$ = require("./../../bower_components/jquery/dist/jquery.js");


var initCustomWidgets = function () {
	require('./modules/nav')();
};

$(initCustomWidgets);
